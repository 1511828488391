import { ExportModal, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { Store } from './store';
import { CreateModal } from './createModal/createModal';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  return (
    <>
      <ProgrammeComponent store={store.programme}/>
      <ExportModal store={store.exportStore}/>
      <CreateModal createModalStore={store.createModalStore}/>
    </>
  );
}

