import { message, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request } from 'egenie-utils';
import React from 'react';
import { logSubGrid } from '../../utils';
import { goodsDetail } from './goodsDetail';
import type { Store } from './store';
import type { MainItem } from './types';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    pageId: '60193',
    buttons: [
      {
        permissionId: '1',
        text: '新建',
        handleClick: () => {
          context.createModalStore.handleOpen();
        },
      },
      {
        permissionId: '21',
        text: '审核',
        handleClick: () => {
          const selectRows: MainItem[] = context.programme.gridModel.gridModel.selectRows;

          if (selectRows.length === 0) {
            const error = '请至少选择一条数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          if (selectRows.some((item) => item.approvedStatus)) {
            const error = '仅未审核的单据可以审核,请重新选择';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            title: '确认审核?',
            onOk: () => request({
              url: '/api/cloud/pms/transfer/order/approve',
              method: 'POST',
              data: { wmsTransferOrderIds: selectRows.map((item) => item.cloudWmsTransferOrderId) },
            })
              .then(() => {
                message.success('审核成功');
                context.programme.gridModel.gridModel.resetAll();
                context.programme.gridModel.onRefresh();
              }),
          });
        },
      },
      {
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(context.programme.gridModel.gridModel.selectedIds).join(',');
          const fileName = '调拨单';
          const exportType = 'cloud_wms_transfer_order';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'approvedStatusName',
          name: '审核状态',
          width: 60,
        },
        {
          key: 'cloudWmsTransferOrderNo',
          name: '调拨单编码',
          width: 200,
        },
        {
          key: 'approvedAuthorName',
          name: '审核人',
          width: 100,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          key: 'transferTypeName',
          name: '调拨类型',
          width: 100,
        },
        {
          key: 'targetWarehouseName',
          name: '目标仓库',
          width: 150,
        },
        {
          key: 'transferWarehouseAreaName',
          name: '调拨库区',
          width: 150,
        },
        {
          key: 'otherStockOrderNo',
          name: '出库单编码',
          width: 150,
        },
        {
          key: 'wmsReceiveOrderNo',
          name: '收货单编码',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          sortable: true,
        },
        {
          key: 'approvedTime',
          name: '审核时间',
          width: 150,
          sortable: true,
        },
        {
          key: 'creatorName',
          name: '创建人',
          width: 150,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudWmsTransferOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockTransfer/index',
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/pms/transfer/order/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        goodsDetail(),
        logSubGrid(170000),
      ],
    },
  });
}
