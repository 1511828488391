import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, request } from 'egenie-utils';
import React from 'react';
import type { GoodsDetailItem } from './types';

export function goodsDetail() {
  return {
    tab: {
      name: '调拨明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'productName',
          name: '商品名称',
          width: 200,
        },
        {
          key: 'productNo',
          name: '商品编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'sku编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 200,
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
          width: 50,
        },
        {
          key: 'transferNum',
          name: '调拨数量',
          width: 80,
        },
        {
          key: 'spec',
          name: '规格',
          width: 100,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },

      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudWmsTransferOrderDetailId',
      sortByLocal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockTransfer/index/detail',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<GoodsDetailItem>>({
          url: '/api/cloud/pms/transfer/order/detail/query',
          method: 'POST',
          data: {
            sidx,
            sord,
            page,
            pageSize,
            cloudWmsTransferOrderId: pid,
          },
        });
      },
    },
  };
}
