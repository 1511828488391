export const ENUM_TRANSFER_TYPE = {
  supplierTransfer: {
    value: '1',
    label: '供应商调拨',
  },
  ownerSelfTransfer: {
    value: '2',
    label: '货主自有调拨',
  },
  ownerShareTransfer: {
    value: '3',
    label: '货主共享专属调拨',
  },
};
