import { Card, Modal } from 'antd';
import { NormalProgrammeComponent, MainSubStructure } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { SelectGoodsModal } from './selectGoodsModal/selectGoodsModal';
import type { CreateModalStore } from './createModalStore';

export const CreateModal = observer(({
  createModalStore: {
    handleClose,
    open,
    normalProgramme,
    mainSubStructureModel,
    selectGoodsModalStore,
    isSubmit,
    handleSubmit,
  },
}: { createModalStore: CreateModalStore; }) => {
  return (
    <>
      <Modal
        centered
        confirmLoading={isSubmit}
        destroyOnClose
        maskClosable={false}
        onCancel={handleClose}
        onOk={handleSubmit}
        open={open}
        title="新建调拨单"
        width={1000}
      >
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div style={{ height: 500 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
      <SelectGoodsModal selectGoodsModalStore={selectGoodsModalStore}/>
    </>
  );
});

