import { ExportStore, Programme } from 'egenie-utils';
import React from 'react';
import { getOtherWarehouse, getOwner } from '../../utils';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';
import { CreateModalStore } from './createModal/createModalStore';

export class Store {
  constructor() {
    getOtherWarehouse()
      .then((data) => {
        this.programme.filterItems.addDict({ targetWarehouseId: data });
        this.createModalStore.setWarehouseData(data);
      });
    getOwner()
      .then((data) => {
        this.programme.filterItems.addDict({ ownerId: data });
        this.createModalStore.setOwnerData(data);
      });
  }

  public createModalStore: CreateModalStore = new CreateModalStore(this);

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public programme: Programme = new Programme({
    gridModel: mainSubStructureModel(this),
    filterItems: filterItems(this),
    moduleName: 'egenieCloudWmsStockTransferIndex',
  });
}
