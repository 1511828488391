import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './store';

export function filterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: 'createTime',
      data: [
        {
          value: 'createTime',
          label: '创建时间',
        },
      ],
    },
    {
      mode: 'multiple',
      type: 'select',
      label: '目标仓库',
      field: 'targetWarehouseId',
    },
    {
      type: 'select',
      label: '货主',
      field: 'ownerId',
    },
    {
      type: 'select',
      label: '审核状态',
      field: 'approvedStatus',
      data: [
        {
          value: '1',
          label: '已审核',
        },
        {
          value: '0',
          label: '未审核',
        },
      ],
    },
    {
      type: 'input',
      label: '调拨单编码',
      field: 'cloudWmsTransferOrderNo',
    },
    {
      type: 'input',
      label: '出库单编码',
      field: 'otherStockOrderNo',
    },
    {
      type: 'input',
      label: '收货单编码',
      field: 'wmsReceiveOrderNo',
    },
    {
      type: 'input',
      label: 'sku编码',
      field: 'skuNo',
    },
  ];
}
