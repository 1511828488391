import { Button, message, InputNumber } from 'antd';
import type { BaseData, DictData } from 'egenie-common';
import { formatNumber, objToDict, request } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, NormalProgramme } from 'egenie-utils';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { getAllVendor, getWarehouseArea } from '../../../utils';
import { SelectGoodsModalStore } from './selectGoodsModal/selectGoodsModalStore';
import type { Store } from '../store';
import type { GoodsItem } from '../types';
import { ENUM_TRANSFER_TYPE } from '../constants';

export class CreateModalStore {
  constructor(private parent: Store) {
    getAllVendor()
      .then((data) => this.setSupplierData(data));

    request<BaseData<DictData>>({ url: '/api/cloud/pms/transfer/order/type' })
      .then((info) => this.setTransferTypeData(objToDict(info.data)));

    getWarehouseArea()
      .then((data) => this.setWarehouseAreaData(data));
  }

  private handleResetTable = () => {
    this.mainSubStructureModel.gridModel.clearToOriginal();
  };

  public selectGoodsModalStore: SelectGoodsModalStore = new SelectGoodsModalStore();

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        field: 'targetWarehouseId',
        label: '目标仓库',
        required: true,
        allowClear: false,
        onChangeCallback: () => {
          this.handleResetTable();
        },
      },
      {
        type: 'select',
        field: 'transferWarehouseAreaId',
        label: '调拨库区',
        required: true,
        allowClear: false,
        onChangeCallback: () => {
          this.handleResetTable();
        },
      },
      {
        type: 'select',
        field: 'transferType',
        label: '调拨类型',
        required: true,
        allowClear: false,
        onChangeCallback: (transferType: string) => {
          this.handleResetTable();
          this.normalProgramme.filterItems.updateFilterItem([
            {
              field: 'vendorId',
              showItem: [
                ENUM_TRANSFER_TYPE.ownerShareTransfer.value,
                ENUM_TRANSFER_TYPE.supplierTransfer.value,
              ].includes(transferType),
              value: undefined,
            },
            {
              field: 'ownerId',
              showItem: [
                ENUM_TRANSFER_TYPE.ownerShareTransfer.value,
                ENUM_TRANSFER_TYPE.ownerSelfTransfer.value,
              ].includes(transferType),
              value: undefined,
            },
          ]);
        },
      },
      {
        type: 'select',
        field: 'ownerId',
        label: '货主',
        required: true,
        allowClear: false,
        showItem: false,
        onChangeCallback: () => {
          this.handleResetTable();
        },
      },
      {
        type: 'select',
        field: 'vendorId',
        label: '供应商',
        required: true,
        showItem: false,
        onChangeCallback: () => {
          this.handleResetTable();
        },
      },
      {
        type: 'input',
        field: 'note',
        label: '备注',
      },
    ],
    count: 4,
    showButton: false,
  });

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '添加商品',
        handleClick: () => {
          this.normalProgramme.filterItems.validator()
            .then(() => {
              this.selectGoodsModalStore.setExtraParams(this.normalProgramme.filterItems.params);
              this.selectGoodsModalStore.handleOpen((rows) => {
                rows.forEach((item) => {
                  const oldItem = this.mainSubStructureModel.gridModel.rows.find((val) => val.skuId === item.skuId);
                  if (oldItem) {
                    oldItem._count = Math.min(formatNumber(oldItem.transferNum), oldItem._count + 1);
                  } else {
                    this.mainSubStructureModel.gridModel.rows.push({
                      ...item,
                      _count: formatNumber(item.transferNum),
                    });
                  }
                });
              });
            });
        },
      },
      {
        text: '批量删除',
        handleClick: () => {
          this.handleDeleteItem(this.mainSubStructureModel.gridModel.selectRows);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          formatter: ({ row }) => {
            return (
              <Button
                onClick={() => this.handleDeleteItem([row])}
                type="link"
              >
                删除
              </Button>
            );
          },
          width: 100,
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
          width: 60,
        },
        {
          key: 'productNo',
          name: '商品编码',
        },
        {
          key: 'skuNo',
          name: 'sku编码',
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'transferNum',
          name: '调拨数量',
          width: 100,
          formatter: observer(({
            row,
            rowIdx,
          }: { row: GoodsItem; rowIdx: number; }) => {
            return (
              <InputNumber
                max={formatNumber(row.transferNum)}
                min={0}
                onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx]._count = formatNumber(value)}
                size="small"
                style={{ width: '100%' }}
                value={formatNumber(this.mainSubStructureModel.gridModel.rows[rowIdx]._count)}
              />
            );
          }),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'skuId',
      showSelectedTotal: false,
      showRefresh: false,
      showReset: false,
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: false,
      showPager: false,
    },
    hiddenSubTable: true,
    api: { onQuery: () => Promise.reject() },
  });

  @observable public warehouseData: ValueAndLabelData = [];

  @action public setWarehouseData = (warehouseData: CreateModalStore['warehouseData']) => {
    this.warehouseData = warehouseData;
    this.normalProgramme.filterItems.addDict({ targetWarehouseId: warehouseData });
  };

  @observable public warehouseAreaData: ValueAndLabelData = [];

  @action public setWarehouseAreaData = (warehouseAreaData: CreateModalStore['warehouseAreaData']) => {
    this.warehouseAreaData = warehouseAreaData;
    this.normalProgramme.filterItems.addDict({ transferWarehouseAreaId: warehouseAreaData });
  };

  @observable public ownerData: ValueAndLabelData = [];

  @action public setOwnerData = (ownerData: CreateModalStore['ownerData']) => {
    this.ownerData = ownerData;
    this.normalProgramme.filterItems.addDict({ ownerId: ownerData });
  };

  @observable public supplierData: ValueAndLabelData = [];

  @action public setSupplierData = (supplierData: CreateModalStore['supplierData']) => {
    this.supplierData = supplierData;
    this.normalProgramme.filterItems.addDict({ vendorId: supplierData });
  };

  @observable public transferTypeData: ValueAndLabelData = [];

  @action public setTransferTypeData = (transferTypeData: CreateModalStore['transferTypeData']) => {
    this.transferTypeData = transferTypeData;
    this.normalProgramme.filterItems.addDict({ transferType: transferTypeData });
  };

  @action public handleDeleteItem = (rows: GoodsItem[]) => {
    const deleteIds = rows.map((item) => item.skuId);
    this.mainSubStructureModel.gridModel.rows = this.mainSubStructureModel.gridModel.rows.filter((item) => !deleteIds.includes(item.skuId));
  };

  @observable public open = false;

  @action public handleOpen = () => {
    this.normalProgramme.reset();
    this.handleResetTable();
    this.open = true;
  };

  @action public handleClose = () => {
    this.normalProgramme.reset();
    this.handleResetTable();
    this.open = false;
  };

  @observable public isSubmit = false;

  @action public handleSubmit = async() => {
    await this.normalProgramme.filterItems.validator();
    if (this.mainSubStructureModel.gridModel.rows.length <= 0) {
      const error = '请添加商品';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    try {
      this.isSubmit = true;
      await request({
        url: '/api/cloud/pms/transfer/order/create',
        method: 'post',
        data: {
          ...this.normalProgramme.filterItems.params,
          detailList: this.mainSubStructureModel.gridModel.rows.map((item) => ({
            skuId: item.skuId,
            transferNum: item._count,
          })),
        },
      });
      message.success('创建成功');
      this.handleClose();
      this.parent.programme.gridModel.onQuery();
    } finally {
      this.isSubmit = false;
    }
  };
}
